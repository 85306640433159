var _a;
import { AuthController } from "./controllers/auth.controller";
import Axios from "axios";
import * as base64 from "@juanelas/base64";
import { TextDecoder } from "text-encoding";
import { ContactsController } from "./controllers/contacts.controller";
import { AgenciesController } from "./controllers/agencies.controller";
import { LanguagesController } from "./controllers/languages.controller";
import { MeansController } from "./controllers/means.controller";
import { GeoController } from "./controllers/geo.controller";
import { PropertyTypesController } from "./controllers/property-types.controller";
import { PropertySubTypesController } from "./controllers/property-subtypes.controller";
import { AttributesController } from "./controllers/attributes.controller";
import { DocumentsController } from "./controllers/documents.controller";
import { PropertyTransactionTypesController } from "./controllers/property-transaction-types.controller";
import { ChannelsController } from "./controllers/channels.controller";
import { PropertiesController } from "./controllers/properties.controller";
import { ProjectsController } from "./controllers/projects.controller";
import { DemandsController } from "./controllers/demands.controller";
import { SmartController } from "./controllers/smart.controller";
import { AgendaController } from "./controllers/agenda.controller";
import { MessagesController } from "./controllers/messages.controller";
import { SignaturesController } from "./controllers/signatures.controller";
import { CatastralController } from "./controllers/catastral.controller";
import { RolesController } from "./controllers/roles.controller";
import { UserController } from "./controllers/user.controller";
import { AgentsController } from "./controllers/agents.controller";
import { FacebookController } from "./controllers/facebook.controller";
import { PropertyStatusController } from "./controllers/property-status.controller";
import { AcquisitionsController } from "./controllers/acquisitions.controller";
import { ExternalPropertiesController } from "./controllers/external-properties.controller";
import { AcquisitionsAlertController } from "./controllers/acquisitions-alert.controller";
import { AcquisitionsZoneController } from "./controllers/acquisitions-zone.controller";
import { WebsiteController } from "./controllers/website.controller";
import { HelpController } from "./controllers/help.controller";
import { WalletsController } from "./controllers/wallets.controller";
import { SubscriptionsController } from "./controllers/subscriptions.controller";
import { PlansController } from "./controllers/plans.controller";
import { PaymentsController } from "./controllers/payments.controller";
import { BillingController } from "./controllers/billing.controller";
import { NewslettersController } from "./controllers/newsletters.controller";
import { DomainsController } from "./controllers/domains.controller";
import { IdealistaController } from "./controllers/idealista.controller";
import { NotificationsController } from "./controllers/notifications.controller";
import { CalendarController } from "./controllers/calendar.controller";
import { SerenaController } from "./controllers/serena.controller";
import { PromotionsController } from "./controllers/promotions.controller";
import { PollsController } from "./controllers/polls.controller";
export class ServiestateApi {
    constructor(config) {
        this.signals = {};
        this.refreshingPromise = null;
        this.config = Object.assign({
            endpoint: "/api",
            autoRefresh: false,
        }, config);
        this.axios = Axios.create({
            adapter: this.config.adapter,
            baseURL: this.config.endpoint,
            validateStatus: (status) => status >= 200 && status <= 399,
        });
        if (config.autoRefresh === true)
            this.bindAutoRefresh();
        this.bindUnauthorized();
        this.auth = new AuthController(this.axios);
        this.contacts = new ContactsController(this.axios);
        this.agencies = new AgenciesController(this.axios);
        this.languages = new LanguagesController(this.axios);
        this.means = new MeansController(this.axios);
        this.geo = new GeoController(this.axios);
        this.propertyTypes = new PropertyTypesController(this.axios);
        this.propertyTransactionTypes = new PropertyTransactionTypesController(this.axios);
        this.propertySubTypes = new PropertySubTypesController(this.axios);
        this.propertyStatus = new PropertyStatusController(this.axios);
        this.attributes = new AttributesController(this.axios);
        this.documents = new DocumentsController(this.axios);
        this.channels = new ChannelsController(this.axios);
        this.properties = new PropertiesController(this.axios);
        this.projects = new ProjectsController(this.axios);
        this.demands = new DemandsController(this.axios);
        this.smart = new SmartController(this.axios);
        this.agenda = new AgendaController(this.axios);
        this.messages = new MessagesController(this.axios);
        this.signatures = new SignaturesController(this.axios);
        this.catastral = new CatastralController(this.axios);
        this.roles = new RolesController(this.axios);
        this.user = new UserController(this.axios);
        this.agents = new AgentsController(this.axios);
        this.facebook = new FacebookController(this.axios);
        this.acquisitions = new AcquisitionsController(this.axios);
        this.externalProperties = new ExternalPropertiesController(this.axios);
        this.acquisitionsAlerts = new AcquisitionsAlertController(this.axios);
        this.acquisitionsZone = new AcquisitionsZoneController(this.axios);
        this.website = new WebsiteController(this.axios);
        this.help = new HelpController(this.axios);
        this.wallets = new WalletsController(this.axios);
        this.subscriptions = new SubscriptionsController(this.axios);
        this.plans = new PlansController(this.axios);
        this.payments = new PaymentsController(this.axios);
        this.billing = new BillingController(this.axios);
        this.newsletters = new NewslettersController(this.axios);
        this.domains = new DomainsController(this.axios);
        this.idealista = new IdealistaController(this.axios);
        this.notifications = new NotificationsController(this.axios);
        this.calendar = new CalendarController(this.axios);
        this.serena = new SerenaController(this.axios);
        this.promotions = new PromotionsController(this.axios);
        this.polls = new PollsController(this.axios);
    }
    on(type, callback) {
        var _b;
        const callbacks = (this.signals[type] = (_b = this.signals[type]) !== null && _b !== void 0 ? _b : []);
        callbacks.push(callback);
    }
    setCredentials(token, schema = "Bearer") {
        this.axios.defaults.headers.common["Authorization"] = `${schema} ${token}`;
    }
    clearCredentials() {
        delete this.axios.defaults.headers.common["Authorization"];
    }
    bindAutoRefresh() {
        this.axios.interceptors.request.use(async (config) => {
            var _b, _c, _d, _e, _f;
            const authHeader = (_c = (_b = config.headers) === null || _b === void 0 ? void 0 : _b.Authorization) !== null && _c !== void 0 ? _c : (_e = (_d = config.headers) === null || _d === void 0 ? void 0 : _d.common) === null || _e === void 0 ? void 0 : _e.Authorization;
            const jwt = authHeader === null || authHeader === void 0 ? void 0 : authHeader.toString().split(" ")[1];
            if (jwt && this.isTokenExpired(jwt)) {
                this.refreshingPromise =
                    (_f = this.refreshingPromise) !== null && _f !== void 0 ? _f : this.auth.refresh();
                try {
                    const jwt = await this.refreshingPromise;
                    this.setCredentials(jwt.access_token);
                    config.headers.Authorization = `Bearer ${jwt.access_token}`;
                    this.dispatch("auto-refresh", jwt.access_token);
                }
                catch (e) {
                    this.clearCredentials();
                    this.dispatch("unauthorized");
                }
                finally {
                    this.refreshingPromise = null;
                }
            }
            return config;
        }, undefined, { runWhen: (config) => config.url !== "/auth/refresh" });
    }
    bindUnauthorized() {
        this.axios.interceptors.response.use((response) => {
            if (response.status === 401) {
                this.dispatch("unauthorized", response);
            }
            return response;
        });
    }
    isTokenExpired(token) {
        const base64Url = token.split(".")[1];
        const base64Str = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const strOrBuffer = base64.decode(base64Str);
        let str;
        if (strOrBuffer instanceof Uint8Array) {
            const td = new TextDecoder();
            str = td.decode(strOrBuffer);
        }
        else {
            str = strOrBuffer;
        }
        const { exp } = JSON.parse(str);
        const expired = Date.now() >= exp * 1000;
        return expired;
    }
    dispatch(type, ...params) {
        var _b;
        const callbacks = (_b = this.signals[type]) !== null && _b !== void 0 ? _b : [];
        callbacks.forEach((callback) => callback(...params));
    }
}
_a = ServiestateApi;
ServiestateApi.recaptchaTokenResolver = async () => undefined;
