import { Box, Button, LinearProgress, Paper, Typography } from "@mui/material";
import { PageContent } from "../../atoms/page-content";
import { PageTitle } from "../../atoms/page-title";
import { useAPI } from "../../../hooks/use-api";
import { useQueryString } from "../../../hooks/use-query-string";
import { useCallback } from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

export default function LakimiAuthorize() {
  const api = useAPI();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const {
    query: { c: conversationId },
  } = useQueryString();

  const handleAuthorize = useCallback(async () => {
    if (typeof conversationId === "string") {
      await api.serena.authenticate(conversationId);
      enqueueSnackbar(
        "Se ha autorizado correctamente. Ya puedes disfrutar de Serena.",
        { variant: "success" }
      );
      navigate("/");
    }
  }, [conversationId, api, enqueueSnackbar, navigate]);

  return (
    <PageContent>
      <PageTitle title="Autorización de Serena" />
      <Box display="flex" flexDirection="column" gap={3}>
        <Paper sx={{ p: 3 }}>
          <Typography>
            ¿Autorizas el uso de Serena en tu nuevo dispositivo?
          </Typography>
          <Button variant="outlined" onClick={handleAuthorize}>
            Conceder acceso
          </Button>
        </Paper>
      </Box>
    </PageContent>
  );
}
