import { Box, CircularProgress } from "@mui/material";
import { FC } from "react";

export const PageLoading: FC = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundColor: "#fff",
        zIndex: 9999,
      }}
    >
      <img
        style={{ position: "absolute", display: "block", width: 70 }}
        src="./images/tandem-logo_256.png"
        alt="logo"
      />
      <CircularProgress size="120px" />
    </Box>
  );
};
