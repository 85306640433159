import {
  Box,
  IconButton,
  Paper,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme,
  Typography,
  stepConnectorClasses,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useAgencyProvider } from "../../../providers/agency.provider";
import { useSubscriptionProvider } from "../../../providers/subscription.provider";
import {
  MdCheckCircle,
  MdInfoOutline,
  MdOutlineFeaturedPlayList,
  MdPayments,
} from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { GiFireworkRocket } from "react-icons/gi";
import { AiOutlineMail } from "react-icons/ai";
import dayjs from "../../../dayjs";
import { useNavigate } from "react-router-dom";

export function InitialSteps({ usePaper }: { usePaper?: boolean }) {
  const { agency } = useAgencyProvider();
  const { subscription } = useSubscriptionProvider();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.only("xs")
  );

  const isFreeVersion = subscription?.subscription?.plan.id === "FREE";

  const steps: { label: string; completed: boolean }[] = [
    {
      label: "Confirma tu email",
      completed:
        agency?.initial_steps_completed.includes("email_verified") ?? false,
    },
    {
      label: "Datos de facturación",
      completed:
        agency?.initial_steps_completed.includes("fiscal_data") ?? false,
    },
    {
      label: "Forma de pago",
      completed: agency?.initial_steps_completed.includes("payment") ?? false,
    },
    {
      label: "Elige tu plan",
      completed:
        agency?.initial_steps_completed.includes("plan_changed") ?? false,
    },
    {
      label: "Disfruta de Tándem",
      completed: false,
    },
  ];

  const Content = (
    <>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center", mb: 2 }}>
        {!isMobile && <MdInfoOutline size={50} color="#68cbf8" />}
        <Box>
          {isFreeVersion && (
            <Typography fontSize={22} gutterBottom>
              ¡Estás disfrutando de tu periodo de prueba! Quedan{" "}
              {dayjs.utc(subscription?.next_state_date).fromNow(true)}.
            </Typography>
          )}

          <Typography>
            Completa los siguientes pasos para poder tener el mejor software
            inmobiliario:
          </Typography>
        </Box>
      </Box>

      <Stepper
        activeStep={-1}
        alternativeLabel={!isMobile}
        nonLinear
        orientation={isMobile ? "vertical" : "horizontal"}
        connector={<ColorlibConnector />}
        sx={{ paddingBottom: 1 }}
      >
        {steps.map((x, i) => (
          <Step key={i} completed={x.completed}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{
                "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
                  mt: 0,
                  fontWeight: 600,
                },
                "& .MuiStepLabel-label": {
                  display: "inline-flex",
                  alignItems: "center",
                  gap: 0.5,
                },
              }}
            >
              {x.label}{" "}
              {x.completed && <MdCheckCircle color="green" size={20} />}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );

  if (usePaper) {
    return (
      <Paper elevation={5} sx={{ p: 2, mb: 2 }}>
        {Content}
      </Paper>
    );
  }

  return <Box>{Content}</Box>;
}

function CustomStepIcon(props: StepIconProps) {
  const { active, completed } = props;

  const navigate = useNavigate();

  const icons: { [index: string]: React.ReactElement } = {
    1: <AiOutlineMail size={40} />,
    2: <LiaFileInvoiceDollarSolid size={40} />,
    3: <MdPayments size={40} />,
    4: <MdOutlineFeaturedPlayList size={40} />,
    5: <GiFireworkRocket size={40} />,
  };

  const links: { [index: string]: (() => void) | null } = {
    1: () => {
      navigate("/my-account/profile");
    },
    2: () => {
      navigate("/administration/billing");
    },
    3: () => {
      navigate("/administration/payment-methods");
    },
    4: () => {
      navigate("/administration/subscription");
    },
    5: null,
  };

  const icon = icons[String(props.icon)];
  const action = links[String(props.icon)];

  return (
    <Box sx={{ backgroundColor: "#fff", zIndex: 1 }}>
      {action ? (
        <IconButton
          color={active ? "primary" : completed ? "success" : "secondary"}
          size="large"
          onClick={action}
          sx={{ m: 0 }}
        >
          {icon}
        </IconButton>
      ) : (
        <IconButton
          disabled
          color={active ? "primary" : completed ? "success" : "secondary"}
          size="large"
        >
          {icon}
        </IconButton>
      )}
    </Box>
  );
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 30,
  },
  // [`&.${stepConnectorClasses.active}`]: {
  //   [`& .${stepConnectorClasses.line}`]: {
  //     backgroundColor: "#68cbf8",
  //   },
  // },
  // [`&.${stepConnectorClasses.completed}`]: {
  //   [`& .${stepConnectorClasses.line}`]: {
  //     backgroundColor: "#68cbf8",
  //   },
  // },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
    [theme.breakpoints.only("xs")]: {
      height: "auto",
      width: 3,
      marginLeft: 17,
    },
  },
}));
