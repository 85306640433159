import React, { FC, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  HashRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { ProtectedRoute } from "./components/pages/protected-page";
import { AuthProvider } from "./providers/auth.provider";
import { UserProvider } from "./providers/user.provider";
import { ConfirmationDialogProvider } from "./providers/confirmation-dialogs.provider";
import { PageLoading } from "./components/organisms/page-loading";
import { Box } from "@mui/material";
import { ErrorNotFound } from "./components/templates/error-not-found";
import { AgencyProvider } from "./providers/agency.provider";
import { WalletsProvider } from "./providers/wallets.provider";
import { SubscriptionProvider } from "./providers/subscription.provider";
import PaymentPage from "./components/pages/payment";
import { RoleProvider } from "./providers/role.provider";
import { NotificationsProvider } from "./providers/notifications.provider";
import config from "./config";
import LakimiAuthorize from "./components/pages/lakimi/authorize";

const AgendaPage = React.lazy(() => import("./components/pages/agenda"));
const REAuthPage = React.lazy(() => import("./components/pages/reauth"));
const HomePage = React.lazy(() => import("./components/pages/home"));
const ContactsPage = React.lazy(() => import("./components/pages/contacts"));
const PropertiesPage = React.lazy(
  () => import("./components/pages/properties")
);
const ProjectsPage = React.lazy(() => import("./components/pages/projects"));
const LoginPage = React.lazy(() => import("./components/pages/login"));
const RecoveryPasswordPage = React.lazy(
  () => import("./components/pages/recovery-password")
);
const ResetPasswordPage = React.lazy(
  () => import("./components/pages/reset-password")
);
const DemandsPage = React.lazy(() => import("./components/pages/demands"));
const MessagesPage = React.lazy(() => import("./components/pages/messages"));
const AcquisitionPage = React.lazy(
  () => import("./components/pages/acquisition")
);
const PollsPage = React.lazy(() => import("./components/pages/polls"));

const AdminPage = React.lazy(() => import("./components/pages/admin"));

const OnBoardingPage = React.lazy(
  () => import("./components/pages/onboarding")
);

const SignatureFlowPage = React.lazy(
  () => import("./components/pages/signature-flow")
);

const MyAccountPage = React.lazy(() => import("./components/pages/my-account"));

const WebPage = React.lazy(() => import("./components/pages/web"));

const NewslettersPage = React.lazy(
  () => import("./components/pages/newsletters")
);

const PlanAgreementPage = React.lazy(
  () => import("./components/pages/plan-agreement")
);

const PromotionAgreementPage = React.lazy(
  () => import("./components/pages/promotion-agreement")
);

function App() {
  return (
    <AppProviders>
      <Box minHeight="100vh">
        <Suspense fallback={<PageLoading />}>
          <Routes>
            <Route element={<TrackerRoute />}>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/agenda" element={<AgendaPage />} />
                <Route path="/contacts" element={<ContactsPage />} />
                <Route path="/web" element={<WebPage />} />
                <Route path="/contacts/:id" element={<ContactsPage />} />
                <Route path="/properties" element={<PropertiesPage />} />
                <Route path="/properties/:id" element={<PropertiesPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/projects/:id" element={<ProjectsPage />} />
                <Route path="/demands" element={<DemandsPage />} />
                <Route path="/demands/:id" element={<DemandsPage />} />
                <Route path="/newsletters" element={<NewslettersPage />} />
                <Route path="/messages" element={<MessagesPage />} />
                <Route path="/messages/:id" element={<MessagesPage />} />
                <Route path="/acquisition/*" element={<AcquisitionPage />} />
                <Route path="/polls" element={<PollsPage />} />
                <Route path="/polls/:id" element={<PollsPage />} />
                <Route path="/administration/*" element={<AdminPage />} />
                <Route path="/my-account/*" element={<MyAccountPage />} />
                <Route path="/lakimi/auth" element={<LakimiAuthorize />} />
                <Route path="*" element={<ErrorNotFound />} />
              </Route>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/reauth" element={<REAuthPage />} />
              <Route
                path="/recovery-password"
                element={<RecoveryPasswordPage />}
              />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route path="/signature-flow" element={<SignatureFlowPage />} />
              <Route
                path="/plan-agreement/:id"
                element={<PlanAgreementPage />}
              />
              <Route
                path="/promotion-agreement/:id"
                element={<PromotionAgreementPage />}
              />
              <Route path="/onboarding/*" element={<OnBoardingPage />} />
              <Route path="/payment/*" element={<PaymentPage />} />
              <Route path="*" element={<ErrorNotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </Box>
    </AppProviders>
  );
}

const SystemRouter = config.IS_CORDOVA ? HashRouter : BrowserRouter;

function AppProviders({ children }: React.PropsWithChildren) {
  return (
    <SystemRouter>
      <ConfirmationDialogProvider>
        <SnackbarProvider maxSnack={3}>
          <AuthProvider>
            <AgencyProvider>
              <UserProvider>
                <RoleProvider>
                  <NotificationsProvider>
                    <SubscriptionProvider>
                      <WalletsProvider>{children}</WalletsProvider>
                    </SubscriptionProvider>
                  </NotificationsProvider>
                </RoleProvider>
              </UserProvider>
            </AgencyProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ConfirmationDialogProvider>
    </SystemRouter>
  );
}

export const TrackerRoute: FC = () => {
  const location = useLocation();

  useEffect(() => {
    if ("gtag" in window) {
      (window as any).gtag("event", "page_view", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return <Outlet />;
};

export default App;
