import { parseException } from "../errors";
export class SerenaController {
    constructor(axios) {
        this.axios = axios;
    }
    async authenticate(conversationId) {
        try {
            await this.axios.post(`/serena/authenticate`, {
                conversation: conversationId,
            });
        }
        catch (e) {
            throw parseException(e);
        }
    }
    async getWidgetConfig() {
        try {
            const response = await this.axios.get(`/serena/widget-config`);
            return response.data;
        }
        catch (e) {
            throw parseException(e);
        }
    }
}
