import { Box, Divider, Typography } from "@mui/material";
import { FC } from "react";

export type PageTitleProps = {
  title: string;
  subtitle?: React.ReactNode;
  divider?: boolean;
  action?: React.ReactNode;
  icon?: React.ReactNode;
};

export const PageTitle: FC<PageTitleProps> = ({
  title,
  subtitle,
  action,
  divider,
  icon,
}) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: { xs: "space-between", sm: "normal" },
        }}
      >
        <Typography
          variant="h1"
          fontSize={{ xs: "1.2rem", lg: "2rem" }}
          gutterBottom
          sx={{
            gap: 1,
            alignItems: "center",
          }}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="h2"
            fontSize={{ xs: "0.8rem", lg: "1.4rem" }}
            gutterBottom
            sx={{
              gap: 1,
              alignItems: "center",
              ml: 1,
              mt: { xs: 0, md: 1 },
            }}
          >
            {subtitle}
            {icon}
          </Typography>
        )}

        {action && (
          <Box sx={{ display: "flex", alignItems: "center" }}>{action}</Box>
        )}
      </Box>

      {divider && <Divider />}
    </Box>
  );
};
