import Plausible from "plausible-tracker";

const plausible = Plausible({
  trackLocalhost: false,
  apiHost: "https://analytics.tandemcrmia.com",
  domain: "app.tandemcrmia.com",
});

plausible.enableAutoPageviews();

export default plausible;
