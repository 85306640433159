import { styled } from "@mui/material";
import { FC } from "react";

export type LogoServiestateProps = {
  size?: "small" | "medium" | "large" | "auto";
};

export const LogoServiestate: FC<LogoServiestateProps> = ({ size }) => {
  let maxWidth: number | null = null;
  let maxWidthLg: number | null = null;

  switch (size) {
    case "small":
      maxWidth = 120;
      maxWidthLg = 160;
      break;
    case "medium":
      maxWidth = 160;
      maxWidthLg = 200;
      break;
    case "large":
      maxWidth = 240;
      maxWidthLg = 280;
      break;
    case "auto":
    default:
      maxWidth = null;
      maxWidthLg = null;
      break;
  }

  return (
    <Image
      maxWidth={maxWidth}
      maxWidthLg={maxWidthLg}
      src="/images/tandem-logo.png"
      alt="Tándem"
    />
  );
};

const Image = styled("img", {
  shouldForwardProp: (prop) =>
    !["maxWidth", "maxWidthLg"].includes(prop.toString()),
})<{
  maxWidth: number | null;
  maxWidthLg: number | null;
}>(({ theme, maxWidth, maxWidthLg }) => ({
  width: "auto",
  height: "auto",
  maxWidth: maxWidth !== null ? maxWidth : "100%",
  maxHeight: "100%",
  [theme.breakpoints.up("lg")]: {
    maxWidth: maxWidthLg !== null ? maxWidthLg : "100%",
  },
}));
