import { styled } from "@mui/material";

export const PageContent = styled("main")<{ noPadding?: boolean }>(
  ({ theme, noPadding }) => ({
    padding: noPadding ? 0 : theme.spacing(3),
    flex: 1,
    display: "flex",
    flexDirection: "column",
  })
);
